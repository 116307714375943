import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { Neurosity } from "@neurosity/sdk";
import { Login } from "./pages/Login";
import { Logout } from "./pages/Logout";
import { Home } from "./pages/Home";
import { Calm } from "./pages/Calm";
import { Focus } from "./pages/Focus";
import { Brainwaves } from "./pages/Brainwaves";
import { Performance } from "./pages/Perform";
import { Training } from "./pages/Training";
import { Predict } from "./pages/Predict";



function AppRoutes() {
  const navigate = useNavigate();
  const [neurosity, setNeurosity] = useState(null);
  const [user, setUser] = useState(null);
  const [deviceId, setDeviceId] = useLocalStorage("deviceId", "72290baa8e3a6da6ebac56c84c2b2127");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (deviceId) {
      console.log("Initializing Neurosity with deviceId:", deviceId);
      const instance = new Neurosity();
      
      // First get the devices list
      instance.getDevices()
        .then(devices => {
          const device = devices.find(d => d.deviceId === deviceId);
          if (device) {
            return instance.selectDevice(device.deviceId);
          } else {
            throw new Error("Device not found in devices list");
          }
        })
        .then(() => {
          console.log("Device selected in App.js");
          setNeurosity(instance);
        })
        .catch(error => {
          console.error("Error selecting device:", error);
          setLoading(false);
        });
    } else {
      console.log("No deviceId available");
      setLoading(false);
    }
  }, [deviceId]);

  useEffect(() => {
    if (!neurosity) {
      return;
    }

    console.log("Setting up auth state subscription");
    const subscription = neurosity.onAuthStateChanged().subscribe((currentUser) => {
      console.log("Auth state changed:", currentUser);
      setUser(currentUser);
      setLoading(false);
    });

    return () => {
      subscription.unsubscribe();
    };  
  }, [neurosity]);

  useEffect(() => {
    if (neurosity && user && deviceId) {
      console.log("Selecting device after auth");
      neurosity.selectDevice(deviceId)
        .then(() => {
          console.log("Device selected successfully");
        })
        .catch(error => {
          console.error("Error selecting device:", error);
        });
    }
  }, [neurosity, user, deviceId]);

  if (loading) {
    return <div style={styles.loadingContainer}>Loading...</div>;
  }

  return (
    <div style={styles.container}>
      <Routes>
        <Route 
          path="/" 
          element={
            <div style={styles.centerContent}>
              <Login
                setNeurosity={setNeurosity}
                setUser={setUser}
                setDeviceId={setDeviceId}
              />
            </div>
          } 
        />
        <Route 
          path="/home" 
          element={
            user ? (
              <Home neurosity={neurosity} user={user} />
            ) : (
              <Navigate to="/" replace />
            )
          } 
        />
        <Route 
          path="/focus" 
          element={
            user ? (
              <Focus neurosity={neurosity} user={user} />
            ) : (
              <Navigate to="/" replace />
            )
          } 
        />
        <Route 
          path="/calm" 
          element={
            user ? (
              <Calm neurosity={neurosity} user={user} />
            ) : (
              <Navigate to="/" replace />
            )
          } 
        />
        <Route 
          path="/brainwaves" 
          element={
            user ? (
              <Brainwaves neurosity={neurosity} user={user} />
            ) : (
              <Navigate to="/" replace />
            )
          } 
        />
        <Route 
          path="/training" 
          element={
            user ? (
              <Training neurosity={neurosity} user={user} />
            ) : (
              <Navigate to="/" replace />
            )
          } 
        />
        <Route 
          path="/predict" 
          element={
            user ? (
              <Predict neurosity={neurosity} user={user} />
            ) : (
              <Navigate to="/" replace />
            )
          } 
        />
        <Route 
          path="/perform" 
          element={
            user ? (
              <Performance neurosity={neurosity} user={user} />
            ) : (
              <Navigate to="/" replace />
            )
          } 
        />
        <Route 
          path="/logout" 
          element={
            <Logout
              neurosity={neurosity}
              resetState={() => {
                setNeurosity(null);
                setUser(null);
                setDeviceId("");
              }}
            />
          } 
        />
      </Routes>
    </div>
  );
}

const styles = {
  container: {
    minHeight: '100vh',
    background: '#0A0A0B',
  },
  centerContent: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },
  loadingContainer: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#0A0A0B',
    color: '#FFFFFF',
    fontSize: '18px',
  }
};

export default function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}
