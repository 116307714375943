import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const PerformanceContainer = styled.main`
  min-height: 100vh;
  background: linear-gradient(135deg, #000000 0%, #1a0f2e 100%);
  position: relative;
  overflow: hidden;
  padding-top: 4rem;
  margin-top: 2rem;
`;

const VisualizationCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ControlPanel = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 12px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 100;
`;

const MetricDisplay = styled.div`
  color: #fff;
  font-size: 1.2rem;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  span {
    color: ${props => props.value > 0.7 ? '#7579ff' : '#b224ef'};
    font-weight: bold;
  }
`;

const DeviceInfo = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: white;
  font-size: 0.9rem;
  background: rgba(0, 0, 0, 0.7);
  padding: 1rem;
  border-radius: 8px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 101;
`;

const StatusIndicator = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.status === 'online' ? '#7579ff' : '#ff4444'};
  margin-right: 8px;
`;

const StatusText = styled.span`
  color: white;
`;

const NavContainer = styled.nav`
  position: fixed;
  top: 1rem;
  left: 1rem;
  display: flex;
  gap: 1rem;
  z-index: 1000;
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
`;

const GuideOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.8);
  padding: 3rem;
  border-radius: 16px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  max-width: 700px;
  z-index: 200;
  font-family: -apple-system, BlinkMacSystemFont, "SF Pro Display", "Helvetica Neue", sans-serif;

  h2 {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    letter-spacing: -0.02em;
  }

  p {
    font-size: 1.25rem;
    line-height: 1.5;
    margin-bottom: 2rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
  }

  ul {
    text-align: left;
    margin: 2rem auto;
    max-width: 500px;
  }

  li {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    line-height: 1.4;
    color: rgba(255, 255, 255, 0.9);
  }

  transition: opacity 0.5s ease;
  opacity: ${props => props.isDisappearing ? 0 : 1};
`;

const InsightMessage = styled.div`
  position: fixed;
  bottom: ${props => props.showControlPanel ? '120px' : '2rem'};
  left: 50%;
  transform: translateX(-50%);
  color: white;
  background: rgba(0, 0, 0, 0.7);
  padding: 1rem 2rem;
  border-radius: 8px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 0.3s ease;
  z-index: 100;
`;

const Button = styled.button`
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: white;
  padding: 0.8rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  backdrop-filter: blur(10px);
  font-family: inherit;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }

  & + & {
    margin-left: 1rem;
  }
`;

const ControlButtons = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 100;
  display: flex;
  gap: 1rem;
`;

const CompactGuide = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  padding: 1rem;
  border-radius: 8px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  display: flex;
  gap: 1.5rem;
  z-index: 100;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 0.3s ease;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
  }
`;

const PortalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 150;
  opacity: ${props => props.show ? 1 : 0};
`;

const PortalHalf = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  background: #000000;
  transition: transform 1.5s cubic-bezier(0.7, 0, 0.3, 1);

  &.left {
    left: 0;
    border-right: 2px solid #7579ff;
    transform: ${props => props.isOpen ? 'translateX(-100%)' : 'translateX(0)'};
  }

  &.right {
    right: 0;
    border-left: 2px solid #7579ff;
    transform: ${props => props.isOpen ? 'translateX(100%)' : 'translateX(0)'};
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 10px;
    background: linear-gradient(to bottom, transparent, #7579ff, transparent);
    opacity: 0.5;
  }

  &.left::after {
    right: -5px;
  }

  &.right::after {
    left: -5px;
  }
`;

const VISUALIZATION_CONSTANTS = {
  maxSpeedIncrease: 8,      // Increased from 5 to make movement more noticeable
  maxWaveAmplitude: 3,      // Increased from 2 for more dramatic waves
  waveFrequency: 0.02,
  particleCount: 300,
  baseSpeed: 0.5,
  minBrightness: 30,
  maxBrightness: 110
};

// Constants for gamma processing
const GAMMA_CONSTANTS = {
  MIN_GAMMA: 0.001,    // Lower minimum threshold
  MAX_GAMMA: 1,      // Lower maximum threshold
  HISTORY_SIZE: 30,
};

// Add these constants at the top with other constants
const RIPPLE_CONSTANTS = {
  maxRipples: 5,          // Maximum number of active ripples
  baseRadius: 50,         // Starting radius of ripples
  maxRadius: 800,         // Maximum radius before ripple fades out
  expansionSpeed: 2,      // How fast ripples expand
  thickness: 2,           // Thickness of ripple lines
};

// Add new scaling constants
const SCALING_CONSTANTS = {
  FOCUS_PEAK: 0.7,    // Peak at 70%
  CALM_PEAK: 0.6,     // Peak at 60%
  GAMMA_PEAK: 0.5,    // Peak at 50%
};

function resetParticle(particle, canvasWidth, canvasHeight) {
  particle.x = Math.random() * canvasWidth;
  particle.y = canvasHeight + particle.radius;
  particle.speed = particle.baseSpeed;
  particle.life = 1;
  particle.opacity = Math.random() * 0.5 + 0.3;
}

function drawParticle(ctx, particle, particleOpacity, smoothGamma) {
  const gradient = ctx.createRadialGradient(
    particle.x,
    particle.y,
    0,
    particle.x,
    particle.y,
    particle.radius * 2
  );

  // Use gamma for brightness
  const baseBrightness = VISUALIZATION_CONSTANTS.minBrightness;
  const maxBrightness = VISUALIZATION_CONSTANTS.maxBrightness;
  const brightness = baseBrightness + (maxBrightness - baseBrightness) * smoothGamma;

  gradient.addColorStop(0, `hsla(${particle.hue}, 80%, ${brightness}%, ${particle.opacity * particleOpacity})`);
  gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');

  ctx.beginPath();
  ctx.arc(particle.x, particle.y, particle.radius * 2, 0, Math.PI * 2);
  ctx.fillStyle = gradient;
  ctx.fill();
}

// Add this new type of effect to track ripples
const createRipple = (x, y, canvas) => ({
  x: x || Math.random() * canvas.width,
  y: y || Math.random() * canvas.height,
  radius: RIPPLE_CONSTANTS.baseRadius,
  opacity: 0.5,
  birth: performance.now(),
});

// Add this helper function near other utility functions
function rescaleValue(value, peakAt) {
  // This will create a curve that reaches 1.0 at the specified peak value
  return Math.min(1, value / peakAt);
}

export function Performance({ neurosity, user }) {
  const canvasRef = useRef(null);
  const animationFrameIdRef = useRef(null);

  const focusRef = useRef(0);
  const calmRef = useRef(0);
  const gammaRef = useRef(0);
  const normalizedGammaRef = useRef(0);
  const smoothFocusRef = useRef(0);
  const smoothCalmRef = useRef(0);
  const smoothGammaRef = useRef(0);
  const particleOpacityRef = useRef(0);

  const lastFrameTime = useRef(0);
  const particlesRef = useRef([]);

  const gammaHistoryRef = useRef([]);

  const [deviceStatus, setDeviceStatus] = useState({});
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [showGuide, setShowGuide] = useState(true);
  const [insight, setInsight] = useState('');
  const [isRunning, setIsRunning] = useState(false);
  const [showCompactGuide, setShowCompactGuide] = useState(false);
  const [isPortalOpen, setIsPortalOpen] = useState(false);
  const [isGuideDisappearing, setIsGuideDisappearing] = useState(false);
  const [signalQuality, setSignalQuality] = useState(null);

  const lerp = (start, end, factor) => start * (1 - factor) + end * factor;

  // Add inside Performance component, with other refs
  const ripplesRef = useRef([]);
  const lastRippleTime = useRef(0);

  useEffect(() => {
    if (!neurosity || !user) return;

    const calmSubscription = neurosity.calm().subscribe((calm) => {
      calmRef.current = Number(calm.probability.toFixed(3));
    });

    const focusSubscription = neurosity.focus().subscribe((focus) => {
      focusRef.current = Number(focus.probability.toFixed(3));
    });

    const gammaSubscription = neurosity.brainwaves("powerByBand").subscribe((bands) => {
      if (bands?.data?.gamma) {
        const rawGamma = bands.data.gamma.reduce((sum, val) => sum + val, 0) / 8;

        gammaHistoryRef.current.push(rawGamma);
        if (gammaHistoryRef.current.length > GAMMA_CONSTANTS.HISTORY_SIZE) {
          gammaHistoryRef.current.shift();
        }

        const avgGamma = gammaHistoryRef.current.reduce((sum, val) => sum + val, 0) 
          / gammaHistoryRef.current.length;

        const maxInHistory = Math.max(...gammaHistoryRef.current);
        const minInHistory = Math.min(...gammaHistoryRef.current);

        const range = Math.max(maxInHistory - minInHistory, GAMMA_CONSTANTS.MIN_GAMMA);
        const normalizedValue = Math.max(0, Math.min(1, 
          (avgGamma - minInHistory) / range
        ));

        const scaledGamma = Math.pow(
          rescaleValue(normalizedValue, SCALING_CONSTANTS.GAMMA_PEAK), 
          1.2
        );

        normalizedGammaRef.current = scaledGamma;
        gammaRef.current = rawGamma;
      }
    });

    const deviceStatusSubscription = neurosity.status().subscribe((status) => {
      setDeviceStatus(status);
    });

    neurosity.getInfo().then((info) => {
      setDeviceInfo(info);
    });

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d', { alpha: false });
    if (!ctx) return;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    function initializeParticles() {
      particlesRef.current = Array(VISUALIZATION_CONSTANTS.particleCount).fill().map(() => ({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 3 + 1,
        baseSpeed: Math.random() * VISUALIZATION_CONSTANTS.baseSpeed + 0.5,
        speed: 0,
        targetSpeed: 0,
        hue: Math.random() * 60 + 240,
        angle: Math.random() * Math.PI * 2,
        wobble: Math.random() * 2 * Math.PI,
        wobbleSpeed: Math.random() * 0.02,
        brightness: VISUALIZATION_CONSTANTS.minBrightness,
        targetBrightness: VISUALIZATION_CONSTANTS.minBrightness,
        peakBrightness: Math.random() * 20 + 90,
        opacity: Math.random() * 0.5 + 0.3,
        life: 1,
        decay: Math.random() * 0.02 + 0.005
      }));
    }

    initializeParticles();

    function resizeCanvas() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      ctx.fillStyle = '#000000';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      initializeParticles();
    }

    window.addEventListener('resize', resizeCanvas);

    function animate(currentTime) {
      animationFrameIdRef.current = requestAnimationFrame(animate);

      if (!showGuide && isRunning) {
        const deltaTime = Math.min((currentTime - lastFrameTime.current) / 16.67, 2);
        lastFrameTime.current = currentTime;

        // Update smooth values
        smoothFocusRef.current = lerp(
          smoothFocusRef.current, 
          rescaleValue(focusRef.current, SCALING_CONSTANTS.FOCUS_PEAK), 
          0.05 * deltaTime
        );
        smoothCalmRef.current = lerp(
          smoothCalmRef.current, 
          rescaleValue(calmRef.current, SCALING_CONSTANTS.CALM_PEAK), 
          0.05 * deltaTime
        );
        smoothGammaRef.current = lerp(smoothGammaRef.current, normalizedGammaRef.current, 0.05 * deltaTime);

        // Clear canvas with fade effect
        ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Handle ripple creation based on calm level
        const rippleInterval = 2000 - (smoothCalmRef.current * 1500); // Faster ripples with higher calm
        if (currentTime - lastRippleTime.current > rippleInterval) {
          if (ripplesRef.current.length < RIPPLE_CONSTANTS.maxRipples) {
            ripplesRef.current.push(createRipple(null, null, canvas));
            lastRippleTime.current = currentTime;
          }
        }

        // Update and draw ripples
        ripplesRef.current = ripplesRef.current.filter(ripple => {
          const age = currentTime - ripple.birth;
          const progress = age / 3000; // 3 seconds lifetime
          
          // Calculate ripple properties
          ripple.radius += RIPPLE_CONSTANTS.expansionSpeed * smoothCalmRef.current * deltaTime;
          ripple.opacity = Math.max(0, 0.5 * (1 - progress));

          // Draw ripple
          ctx.beginPath();
          ctx.strokeStyle = `hsla(240, 80%, 70%, ${ripple.opacity * smoothCalmRef.current})`;
          ctx.lineWidth = RIPPLE_CONSTANTS.thickness;
          ctx.arc(ripple.x, ripple.y, ripple.radius, 0, Math.PI * 2);
          ctx.stroke();

          // Keep ripple if it hasn't reached max size
          return ripple.radius < RIPPLE_CONSTANTS.maxRadius;
        });

        // Update and draw particles with wave influence from ripples
        particlesRef.current.forEach((particle) => {
          particle.targetSpeed = particle.baseSpeed + 
            smoothFocusRef.current * VISUALIZATION_CONSTANTS.maxSpeedIncrease;
          particle.speed = lerp(particle.speed, particle.targetSpeed, 0.1 * deltaTime);
          particle.y -= particle.speed * deltaTime;

          // Calculate ripple influence on particle
          let rippleEffect = { x: 0, y: 0 };
          ripplesRef.current.forEach(ripple => {
            const dx = particle.x - ripple.x;
            const dy = particle.y - ripple.y;
            const distance = Math.sqrt(dx * dx + dy * dy);
            const rippleInfluence = Math.sin((distance - ripple.radius) * 0.1) * 
              smoothCalmRef.current * 
              Math.max(0, 1 - distance / ripple.radius) * 
              ripple.opacity;

            rippleEffect.x += (dx / distance || 0) * rippleInfluence;
            rippleEffect.y += (dy / distance || 0) * rippleInfluence;
          });

          // Apply ripple effect to particle position
          particle.x += rippleEffect.x * deltaTime;
          particle.y += rippleEffect.y * deltaTime;

          // Rest of particle updates...
          const baseBrightness = VISUALIZATION_CONSTANTS.minBrightness;
          const brightnessFactor = smoothGammaRef.current;
          particle.targetBrightness = baseBrightness + 
            (particle.peakBrightness - baseBrightness) * brightnessFactor;
          particle.brightness = lerp(particle.brightness, particle.targetBrightness, 0.1 * deltaTime);

          particle.life -= particle.decay * deltaTime;
          if (particle.life <= 0 || particle.y < -particle.radius) {
            resetParticle(particle, canvas.width, canvas.height);
          }

          drawParticle(ctx, particle, particleOpacityRef.current, smoothGammaRef.current);
        });
      }
    }

    animationFrameIdRef.current = requestAnimationFrame(animate);

    // Update signal quality subscription
    const signalQualitySubscription = neurosity.signalQuality().subscribe((quality) => {
      if (quality && quality.length > 0) {
        const average = quality.reduce((sum, channel) => sum + channel.standardDeviation, 0) / quality.length;
        setSignalQuality(average);
      }
    });

    return () => {
      cancelAnimationFrame(animationFrameIdRef.current);
      window.removeEventListener('resize', resizeCanvas);
      deviceStatusSubscription.unsubscribe();
      calmSubscription.unsubscribe();
      focusSubscription.unsubscribe();
      gammaSubscription.unsubscribe();
      signalQualitySubscription.unsubscribe();
    };
  }, [neurosity, user, showGuide, isRunning]);

  const handleStartVisualization = () => {
    setIsGuideDisappearing(true);

    setTimeout(() => {
      setIsPortalOpen(true);
    }, 500);

    setTimeout(() => {
      setShowGuide(false);
      setIsRunning(true);
      setShowCompactGuide(true);

      particleOpacityRef.current = 0;
      const fadeIn = () => {
        particleOpacityRef.current = Math.min(particleOpacityRef.current + 0.02, 1);
        if (particleOpacityRef.current < 1) {
          requestAnimationFrame(fadeIn);
        }
      };
      setTimeout(fadeIn, 100);
    }, 2000);

    setTimeout(() => {
      setShowCompactGuide(false);
    }, 12000);
  };

  const handleRestart = () => {
    setShowGuide(true);
    setIsRunning(false);
    setIsPortalOpen(false);
    setIsGuideDisappearing(false);
  };

  return (
    <PerformanceContainer>
      <VisualizationCanvas ref={canvasRef} />
      
      {showGuide && (
        <>
          <GuideOverlay isDisappearing={isGuideDisappearing}>
            <h2>Welcome to Mind meets Vibes</h2>
            <p>Experience your brainwaves transformed into living art, inspired by groundbreaking performances from Grimes at Ultra and Pretty Lights at Brooklyn Mirage.</p>
            <ul>
              <li>🎯 Focus drives particles upward - higher focus creates dynamic vertical movement, used by performers to build visual intensity</li>
              <li>🌊 Calm generates wave patterns - your relaxation state creates flowing horizontal movements, perfect for ambient moments</li>
              <li>✨ Gamma waves (30-50 Hz) control luminosity - these natural "relaxed awareness" brain rhythms make particles glow brighter</li>
              <li>🎨 All visuals update in real-time at 4Hz (250ms), providing smooth, responsive feedback</li>
            </ul>
            <p>Experiment with different mental states to create your own unique neural artwork. Try alternating between deep focus and peaceful calm to explore the full range of visual effects.</p>
            <Button onClick={handleStartVisualization}>
              Begin Experience
            </Button>
          </GuideOverlay>
          
          {isGuideDisappearing && (
            <PortalContainer show={true}>
              <PortalHalf className="left" isOpen={isPortalOpen} />
              <PortalHalf className="right" isOpen={isPortalOpen} />
            </PortalContainer>
          )}
        </>
      )}
      {!showGuide && (
        <ControlButtons>
          <Button onClick={() => setIsRunning(!isRunning)}>
            {isRunning ? 'Pause' : 'Resume'}
          </Button>
          <Button onClick={handleRestart}>
            Restart
          </Button>
        </ControlButtons>
      )}
      <InsightMessage show={insight !== ''}>
        {insight}
      </InsightMessage>
      {user && (
        <NavContainer>
          <NavLink to="/home">Home</NavLink>
          <NavLink to="/calm">Calm</NavLink>
          <NavLink to="/focus">Focus</NavLink>
          <NavLink to="/brainwaves">Brainwaves</NavLink>
          <NavLink to="/training">Training</NavLink>
          <NavLink to="/predict">Predict</NavLink>
          <NavLink to="/logout">Logout</NavLink>
        </NavContainer>
      )}
      {deviceInfo && (
        <DeviceInfo>
          <p>
            <StatusIndicator status={deviceStatus.state} />
            <StatusText>
              {deviceStatus.state === 'online' ? 'Connected' : 'Disconnected'}
            </StatusText>
          </p>
          <p>Device: {deviceInfo.deviceNickname}</p>
          <p>Battery: {deviceStatus.battery}%</p>
          <p>Signal Quality: {signalQuality ? `${Math.min(100, (signalQuality * 100).toFixed(0))}%` : 'N/A'}</p>
        </DeviceInfo>
      )}
      <ControlPanel>
        <MetricDisplay value={focusRef.current}>
          Focus: <span>{(focusRef.current * 100).toFixed(0)}%</span>
        </MetricDisplay>
        <MetricDisplay value={calmRef.current}>
          Calm: <span>{(calmRef.current * 100).toFixed(0)}%</span>
        </MetricDisplay>
        <MetricDisplay value={normalizedGammaRef.current}>
          Gamma: <span>{(normalizedGammaRef.current * 100).toFixed(0)}%</span>
        </MetricDisplay>
      </ControlPanel>
      <CompactGuide show={showCompactGuide}>
        <div>🎯 Focus = Upward Speed</div>
        <div>🌊 Calm = Wave Pattern</div>
        <div>✨ Gamma = Brightness</div>
      </CompactGuide>
    </PerformanceContainer>
  );
}
