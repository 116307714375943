import React, { useState } from "react";

export function LoginForm({ onLogin, loading, error }) {
  const [deviceId, setDeviceId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [customDeviceId, setCustomDeviceId] = useState("");
  const [showCustomInput, setShowCustomInput] = useState(false);

  const [deviceOptions, setDeviceOptions] = useState([
    { id: "72290baa8e3a6da6ebac56c84c2b2127", label: "Mandeep Chhabra Crown" },
    // Add more devices here as needed
  ]);

  const handleCustomDevice = () => {
    if (customDeviceId.trim()) {
      if (!deviceOptions.some(device => device.id === customDeviceId.trim())) {
        const newDevice = {
          id: customDeviceId.trim(),
          label: `Custom Device ${deviceOptions.length + 1}`
        };
        setDeviceOptions(prevOptions => [...prevOptions, newDevice]);
      }
      setDeviceId(customDeviceId.trim());
      setCustomDeviceId("");
      setShowCustomInput(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleCustomDevice();
    }
  };

  const maskDeviceId = (id) => {
    if (!id) return '';
    return `${id.slice(0, 4)}...${id.slice(-4)}`;
  };

  function onSubmit(event) {
    event.preventDefault();
    
    if (!deviceId || !email || !password) {
      return;
    }

    console.log("Attempting login with:", { 
      deviceId, 
      email,
      hasPassword: !!password 
    });
    
    onLogin({ deviceId, email, password });
  }

  return (
    <form onSubmit={onSubmit} style={styles.form}>
      <h3 style={styles.title}>Login</h3>
      {error && <h4 style={styles.error}>{error}</h4>}
      
      <div style={styles.inputGroup}>
        <select
          value={deviceId}
          onChange={(e) => setDeviceId(e.target.value)}
          required
          style={styles.input}
          disabled={loading}
        >
          <option value="">Select Device ID</option>
          {deviceOptions.map(device => (
            <option key={device.id} value={device.id}>
              {device.label} - {maskDeviceId(device.id)}
            </option>
          ))}
        </select>
        <button 
          type="button" 
          onClick={() => setShowCustomInput(!showCustomInput)}
          style={styles.linkButton}
        >
          {showCustomInput ? 'Cancel' : 'Use custom device ID'}
        </button>
      </div>

      {showCustomInput && (
        <div style={styles.inputGroup}>
          <div style={styles.customDeviceInput}>
            <input
              type="text"
              value={customDeviceId}
              onChange={(e) => setCustomDeviceId(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Enter custom device ID"
              style={{...styles.input, marginBottom: 0}}
              disabled={loading}
            />
            <button
              type="button"
              onClick={handleCustomDevice}
              style={styles.addButton}
              disabled={!customDeviceId.trim()}
            >
              Add
            </button>
          </div>
        </div>
      )}
      
      <div style={styles.inputGroup}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
          style={styles.input}
          disabled={loading}
        />
      </div>
      
      <div style={styles.inputGroup}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
          style={styles.input}
          disabled={loading}
        />
      </div>
      
      <button type="submit" disabled={loading} style={styles.button}>
        {loading ? "Loading..." : "Connect"}
      </button>
    </form>
  );
}

const styles = {
  form: {
    background: '#161618',
    padding: '32px',
    borderRadius: '16px',
    width: '100%',
    maxWidth: '400px',
    boxShadow: '0 8px 32px rgba(0, 240, 255, 0.1)',
  },
  title: {
    color: '#FFFFFF',
    fontSize: '24px',
    marginBottom: '24px',
    fontWeight: '300',
    letterSpacing: '2px',
  },
  inputGroup: {
    marginBottom: '20px',
  },
  input: {
    width: '100%',
    padding: '12px 16px',
    background: '#0A0A0B',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '8px',
    color: '#FFFFFF',
    fontSize: '16px',
    transition: 'all 0.3s ease',
    '&:focus': {
      borderColor: '#00F0FF',
      boxShadow: '0 0 0 2px rgba(0, 240, 255, 0.2)',
    },
    appearance: 'none',
    backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3c/svg%3e")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 12px center',
    backgroundSize: '20px',
  },
  button: {
    width: '100%',
    padding: '12px',
    background: 'linear-gradient(45deg, #00F0FF, #00E5FF)',
    border: 'none',
    borderRadius: '8px',
    color: '#000000',
    fontSize: '16px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 16px rgba(0, 240, 255, 0.3)',
    }
  },
  error: {
    color: '#FF3D71',
    marginBottom: '16px',
    fontSize: '14px',
  },
  linkButton: {
    background: 'none',
    border: 'none',
    color: '#00F0FF',
    fontSize: '14px',
    cursor: 'pointer',
    padding: '8px 0',
    marginTop: '8px',
    textDecoration: 'underline',
  },
  customDeviceInput: {
    display: 'flex',
    gap: '8px',
  },
  addButton: {
    background: '#00F0FF',
    border: 'none',
    borderRadius: '8px',
    padding: '0 16px',
    color: '#000000',
    cursor: 'pointer',
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
};