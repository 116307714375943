import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { Link } from "react-router-dom";

export function Predict({ neurosity, user }) {
  const [selectedLabel, setSelectedLabel] = useState('');
  const [labels, setLabels] = useState([]);
  const [currentProbability, setCurrentProbability] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const subscriptionRef = useRef(null);

  // Fetch available training labels
  useEffect(() => {
    const fetchLabels = async () => {
      try {
        const trainings = await neurosity.training.getLabels();
        setLabels(trainings);
      } catch (error) {
        console.error('Error fetching labels:', error);
      }
    };
    fetchLabels();
  }, [neurosity]);

  // Handle prediction subscription
  useEffect(() => {
    if (!selectedLabel) return;

    // Unsubscribe from previous subscription
    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
    }

    subscriptionRef.current = neurosity.predictions(selectedLabel).subscribe(prediction => {
      setCurrentProbability(prediction.probability);
      
      setTimeSeriesData(prev => {
        const newData = [...prev, {
          time: new Date().toLocaleTimeString(),
          probability: prediction.probability
        }];
        // Keep last 50 data points
        return newData.slice(-50);
      });
    });

    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
    };
  }, [selectedLabel, neurosity]);

  return (
    <PredictContainer>
      {user && (
        <NavContainer>
          <NavLink to="/home">Home</NavLink>
          <NavLink to="/calm">Calm</NavLink>
          <NavLink to="/focus">Focus</NavLink>
          <NavLink to="/training">Training</NavLink>
          <NavLink to="/brainwaves">Brainwaves</NavLink>
          <NavLink to="/perform">Perform</NavLink>
          <NavLink to="/logout">Logout</NavLink>
        </NavContainer>
      )}
      <Header>
        <h1>Thought Prediction</h1>
      </Header>

      <SelectContainer>
        <label>Select Trained Thought Pattern:</label>
        <Select 
          value={selectedLabel} 
          onChange={(e) => setSelectedLabel(e.target.value)}
        >
          <option value="">Select a pattern...</option>
          {labels.map(label => (
            <option key={label} value={label}>{label}</option>
          ))}
        </Select>
      </SelectContainer>

      {selectedLabel && (
        <>
          <ProbabilityMeter>
            <h2>Current Probability</h2>
            <MeterValue>{(currentProbability * 100).toFixed(1)}%</MeterValue>
            <MeterBar>
              <div style={{ width: `${currentProbability * 100}%` }} />
            </MeterBar>
          </ProbabilityMeter>

          <ChartContainer>
            <h2>Probability Over Time</h2>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={timeSeriesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="time" 
                  tick={{ fontSize: 12 }}
                  interval="preserveStartEnd"
                />
                <YAxis 
                  domain={[0, 1]}
                  tick={{ fontSize: 12 }}
                  tickFormatter={value => `${(value * 100).toFixed(0)}%`}
                />
                <Line 
                  type="monotone"
                  dataKey="probability"
                  stroke="#7579ff"
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </ChartContainer>
        </>
      )}
    </PredictContainer>
  );
}

const PredictContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.div`
  margin-bottom: 2rem;
`;

const SelectContainer = styled.div`
  margin-bottom: 2rem;
  
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.8rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  font-size: 1.1rem;
`;

const ProbabilityMeter = styled.div`
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
`;

const MeterValue = styled.div`
  font-size: 3rem;
  font-weight: bold;
  color: #7579ff;
  margin: 1rem 0;
`;

const MeterBar = styled.div`
  width: 100%;
  height: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  overflow: hidden;

  > div {
    height: 100%;
    background: #7579ff;
    transition: width 0.3s ease;
  }
`;

const ChartContainer = styled.div`
  background: rgba(0, 0, 0, 0.2);
  padding: 1.5rem;
  border-radius: 12px;
`;

const NavContainer = styled.nav`
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  gap: 1rem;
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
`;