import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  Line,
  Legend,
  Cell,
  Tooltip,
} from "recharts";

const BrainwavesContainer = styled.main`
  min-height: 100vh;
  background: linear-gradient(135deg, #2e1a2e 0%, #2e163e 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-top: 4rem;
  margin-left: 320px;
  padding-left: 2rem;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 300;
  letter-spacing: 4px;
  margin-bottom: 40px;
  background: linear-gradient(45deg, #b224ef, #7579ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;

const WaveCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

// Frequency bands definition with colors and ranges
const FrequencyBands = {
  // Deep sleep, unconscious processes (measurable range: 2-4 Hz due to hardware filters)
  delta: { range: "2-4 Hz", color: "#1E88E5" },     // Cool Blue

  // Meditation, drowsiness, and memory processing
  theta: { range: "4-7.5 Hz", color: "#7C4DFF" },   // Deep Purple

  // Relaxed alertness, closed eyes, light meditation
  alpha: { range: "7.5-12.5 Hz", color: "#43A047" }, // Green

  // Active thinking, focus, problem-solving
  beta: { range: "12.5-30 Hz", color: "#FB8C00" },   // Orange

  // Peak concentration, perception, learning (measurable up to 45 Hz due to filters)
  gamma: { range: "30-45 Hz", color: "#E53935" }     // Red
};

const ChartCard = styled(WaveCard)`
  height: 400px;
  padding: 2rem;
`;

const ChartTitle = styled.h3`
  color: white;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  font-weight: 300;
`;

const ChartGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
`;

const RawDataGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
`;

const ChannelLabel = styled.div`
  color: ${props => props.color};
  font-size: 0.9rem;
  text-align: center;
`;

const ChannelNames = ['Delta', 'Theta', 'Alpha', 'Beta', 'Gamma'];

const NavContainer = styled.nav`
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  gap: 1rem;
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
`;

// Update the raw data chart styling and layout
const RawEEGCard = styled(ChartCard)`
  height: 600px;
  background: white;
  padding: 1rem;
`;

const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const AutoScaleToggle = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
`;

const StopButton = styled.button`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.3);
  color: white;
  cursor: pointer;
  
  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
`;

const PowerByBandCard = styled(ChartCard)`
  height: 400px;
`;

const DeviceInfo = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: white;
  font-size: 0.9rem;
  text-align: right;
  background: rgba(255, 255, 255, 0.05);
  padding: 1rem;
  border-radius: 8px;
  backdrop-filter: blur(5px);
`;

const StatusIndicator = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.status === 'online' ? '#4CAF50' : '#ff4444'};
  margin-right: 8px;
`;

const StatusText = styled.span`
  color: white;
`;

const GuidePanel = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 2rem;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  width: 300px;
  position: fixed;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  max-height: 90vh;
  overflow-y: auto;
`;

const GuideBand = styled.div`
  margin-bottom: 2rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  min-height: 150px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const BandTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
  color: ${props => props.color};
  display: flex;
  align-items: center;
  gap: 0.8rem;
  position: relative;
`;

const BandRange = styled.span`
  font-size: 0.9rem;
  opacity: 0.8;
  margin-right: auto;
`;

const BandDescription = styled.p`
  font-size: 0.9rem;
  line-height: 1.6;
  margin-top: 0.8rem;
  opacity: 0.9;
  transition: all 0.3s ease;
`;

const ColorDot = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.color};
`;

const GuideNote = styled.div`
  margin-top: 2.5rem;
  font-size: 0.9rem;
  opacity: 0.8;
  padding: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const ChartExplanation = styled(GuideNote)`
  margin-top: 1.5rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
`;

const WaveGraphContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  height: 150px;
`;

const WaveGuide = styled.div`
  width: 300px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 1rem;
  color: white;
`;

const WaveGraphWrapper = styled.div`
  flex-grow: 1;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 1rem;
`;

const BreathingButton = styled.button`
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  color: white;
  padding: 0.3rem 0.6rem;
  font-size: 0.8rem;
  cursor: pointer;
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const SourceNote = styled.div`
  font-size: 0.8rem;
  opacity: 0.7;
  margin-top: 1rem;
  padding-top: 0.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-style: italic;
`;

const BandContent = styled.div`
  transition: all 0.3s ease;
`;

const BreathingContent = styled.div`
  pre {
    white-space: pre-wrap;
    font-size: 0.9rem;
    line-height: 1.6;
  }
`;

const BrainwaveGuide = () => {
  const [activeTooltip, setActiveTooltip] = useState(null);

  const breathingTechniques = {
    delta: `Chandra Bhedana (Left Nostril Breathing):
    • Sit with spine straight
    • Close right nostril with thumb
    • Inhale through left nostril (8s)
    • Hold breath (4s)
    • Exhale through left nostril (16s)
    • Repeat 5-10 minutes before sleep`,
    
    theta: `4-7-8 Breathing:
    • Sit straight, tongue behind upper teeth
    • Exhale completely through mouth
    • Inhale quietly through nose (4s)
    • Hold breath (7s)
    • Exhale through mouth (8s)
    • Repeat 4 cycles`,
    
    alpha: `Box Breathing:
    • Sit comfortably, eyes closed
    • Inhale through nose (4 counts)
    • Hold breath (4 counts)
    • Exhale through mouth (4 counts)
    • Hold empty (4 counts)
    • Repeat 5-10 minutes`,
    
    beta: `Bhastrika (Bellows Breath):
    • Sit comfortably
    • Inhale/exhale forcefully through nose
    • Keep breaths equal duration
    • 10 breath cycles (1s per breath)
    • Deep breath, hold 5s
    • Repeat 3 rounds`,
    
    gamma: `Kapalabhati (Skull-Shining):
    • Sit straight
    • Deep breath in
    • Quick, forceful exhales
    • Allow passive inhales
    • 1-2 breaths per second
    • 30 seconds, then rest
    • Repeat 3 rounds`
  };

  return (
    <GuidePanel>
      <h2 style={{ 
        marginBottom: '2rem',
        fontSize: '1.4rem',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        paddingBottom: '1rem'
      }}>Brainwaves Guide</h2>
      
      {Object.entries(FrequencyBands).map(([band, info]) => (
        <GuideBand key={band}>
          <BandTitle color={info.color}>
            <ColorDot color={info.color} />
            {band.charAt(0).toUpperCase() + band.slice(1)}
            <BandRange>({info.range})</BandRange>
            <BreathingButton 
              onClick={() => setActiveTooltip(activeTooltip === band ? null : band)}
            >
              {activeTooltip === band ? 'Show Info' : 'Breathing'}
            </BreathingButton>
          </BandTitle>
          
          {activeTooltip === band ? (
            <BreathingContent>
              <pre>
                {breathingTechniques[band]}
              </pre>
              <small style={{ display: 'block', marginTop: '1rem', opacity: 0.7 }}>
                Note: Consult healthcare provider before practicing intensive breathing techniques.
              </small>
              <SourceNote>
                These breathing techniques are derived from ancient yogic practices (pranayama) 
                and are supported by modern neuroscientific research showing their effects on 
                brainwave patterns and nervous system regulation.
              </SourceNote>
            </BreathingContent>
          ) : (
            <BandDescription>
              {(() => {
                switch(band) {
                  case 'delta':
                    return 'The slowest brain waves, primarily associated with deep sleep and unconscious bodily functions. Due to technical limitations, only 2-4 Hz is measurable. Essential for healing and regeneration.';
                  case 'theta':
                    return 'Present during deep meditation, drowsiness, and memory processing. These waves are crucial for learning and memory consolidation. Often associated with creative insights and deep contemplative states.';
                  case 'alpha':
                    return 'Indicates relaxed alertness, especially prominent when closing your eyes. These waves bridge the conscious and subconscious mind. Optimal for light meditation, stress reduction, and fostering a calm, focused state.';
                  case 'beta':
                    return 'Dominant during active thinking, focus, and problem-solving. These waves indicate active engagement with tasks and conscious processing. Higher beta can indicate stress or anxiety, while lower beta relates to focused attention.';
                  case 'gamma':
                    return 'The fastest measurable brain waves (up to 45 Hz), associated with peak concentration, perception, and rapid learning. These waves coordinate information from different brain regions and are linked to heightened awareness and optimal cognitive functioning.';
                  default:
                    return '';
                }
              })()}
            </BandDescription>
          )}
        </GuideBand>
      ))}
      
      <GuideNote>
        <p>Note: The Neurosity device measures brainwave activity across eight channels: CP3, C3, F5, PO3, PO4, F6, C4, and CP4, following the standard 10-20 EEG placement system.</p>
      </GuideNote>

      <ChartExplanation>
        <h3 style={{ marginBottom: '1rem', fontSize: '1.1rem' }}>Understanding Absolute Power</h3>
        <p>The Absolute Power chart at the bottom shows the strength of each brainwave type in real-time. Higher bars indicate stronger presence of that particular wave type:</p>
        <ul style={{ marginTop: '0.8rem', paddingLeft: '1.2rem' }}>
          <li>Higher delta: Deeper relaxation</li>
          <li>Higher theta: Deeper meditation</li>
          <li>Higher alpha: Relaxed awareness</li>
          <li>Higher beta: Active thinking</li>
          <li>Higher gamma: Peak mental activity</li>
        </ul>
        <p style={{ marginTop: '0.8rem' }}>Values are averaged across all channels and updated 4 times per second.</p>
      </ChartExplanation>

      <GuideNote>
        <h3 style={{ marginBottom: '0.8rem', fontSize: '1.1rem' }}>About Breathing Techniques</h3>
        <p>The breathing exercises provided are based on traditional yogic breathing practices (pranayama) 
        that have been practiced for thousands of years. Modern neuroscience research has validated 
        their effectiveness in modulating brainwave patterns and promoting specific mental states. 
        Each technique is carefully matched to support the corresponding brainwave state.</p>
      </GuideNote>
    </GuidePanel>
  );
};

// Add this function before the Brainwaves component
const calculateSMA = (data, windowSize = 3) => {
  if (!data || data.length < windowSize) return [];
  
  const sma = [];
  for (let i = 0; i <= data.length - windowSize; i++) {
    const window = data.slice(i, i + windowSize);
    const average = window.reduce((sum, val) => sum + val, 0) / windowSize;
    sma.push(average);
  }
  return sma;
};

export function Brainwaves({ user, neurosity }) {
  const [deviceStatus, setDeviceStatus] = useState({});
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [rawData, setRawData] = useState([]);
  const [powerByBandData, setPowerByBandData] = useState(null);
  const [psdData, setPsdData] = useState([]);
  const [isRecording, setIsRecording] = useState(true);
  const [autoScale, setAutoScale] = useState(true);
  const [signalQuality, setSignalQuality] = useState(null);

  useEffect(() => {
    if (!user || !neurosity) return;

    neurosity.getInfo().then(setDeviceInfo);
    
    const statusSubscription = neurosity.status().subscribe(setDeviceStatus);
    
    // Subscribe to raw data
    const rawSubscription = neurosity.brainwaves("raw").subscribe((data) => {
      setRawData(current => {
        const newDataPoint = {
          timestamp: new Date().toLocaleTimeString(),
          data: data.data[0], // Take the first sample from the epoch
          sma: data.data[0].map((_, channelIndex) => {
            const channelData = current.slice(-3).map(point => point.data[channelIndex]);
            return calculateSMA(channelData)[0] || data.data[0][channelIndex];
          })
        };
        return [...current.slice(-50), newDataPoint];
      });
    });

    // Subscribe to power by band
    const powerByBandSubscription = neurosity.brainwaves("powerByBand").subscribe((data) => {
      setPowerByBandData(data);
    });

    // Subscribe to frequency data (previously called psd)
    const psdSubscription = neurosity
      .brainwaves("frequency")
      .subscribe((data) => {
        if (data?.data) {
          // Calculate average power for each channel
          const channelAverages = data.data.map(channel => 
            channel.reduce((sum, val) => sum + val, 0) / channel.length
          );

          setPsdData([
            { 
              pair: 'C3 : C4', 
              left: channelAverages[1],  // C3 index
              right: channelAverages[6]  // C4 index
            },
            { 
              pair: 'CP3 : CP4', 
              left: channelAverages[0],  // CP3 index
              right: channelAverages[7]  // CP4 index
            },
            { 
              pair: 'F5 : F6', 
              left: channelAverages[2],  // F5 index
              right: channelAverages[5]  // F6 index
            },
            { 
              pair: 'PO3 : PO4', 
              left: channelAverages[3],  // PO3 index
              right: channelAverages[4]  // PO4 index
            }
          ]);
        }
      });

    // Add signal quality subscription
    const signalQualitySubscription = neurosity.signalQuality().subscribe((quality) => {
      if (quality && quality.length > 0) {
        const average = quality.reduce((sum, channel) => sum + channel.standardDeviation, 0) / quality.length;
        setSignalQuality(average);
      }
    });

    return () => {
      statusSubscription.unsubscribe();
      rawSubscription.unsubscribe();
      powerByBandSubscription.unsubscribe();
      psdSubscription.unsubscribe();
      signalQualitySubscription.unsubscribe();
    };
  }, [user, neurosity]);

  const getPowerByBandChartData = () => {
    if (!powerByBandData?.data) return [];
    
    return Object.entries(FrequencyBands).map(([band, info]) => ({
      name: band.charAt(0).toUpperCase() + band.slice(1),
      value: powerByBandData.data[band]?.reduce((a, b) => a + b, 0) / 8 || 0,
      range: info.range,
      color: info.color,
    }));
  };

  const getBandDescription = (bandName) => {
    switch (bandName.toLowerCase()) {
      case 'delta':
        return 'Associated with deep sleep and unconscious processes';
      case 'theta':
        return 'Present during meditation, drowsiness, and memory processing';
      case 'alpha':
        return 'Indicates relaxed alertness, especially with closed eyes';
      case 'beta':
        return 'Active thinking, focus, and conscious problem-solving';
      case 'gamma':
        return 'Peak concentration, perception, and rapid learning';
      default:
        return '';
    }
  };

  return (
    <BrainwavesContainer>
      <BrainwaveGuide />
      {user && (
        <NavContainer>
          <NavLink to="/home">Home</NavLink>
          <NavLink to="/calm">Calm</NavLink>
          <NavLink to="/focus">Focus</NavLink>
          <NavLink to="/training">Training</NavLink>
          <NavLink to="/predict">Predict</NavLink>
          <NavLink to="/perform">Perform</NavLink>
          <NavLink to="/logout">Logout</NavLink>
        </NavContainer>
      )}

      {deviceInfo && (
        <DeviceInfo>
          <p>
            <StatusIndicator status={deviceStatus.state} />
            <StatusText>
              {deviceStatus.state === 'online' ? 'Connected' : 'Disconnected'}
            </StatusText>
          </p>
          <p>Device: {deviceInfo.deviceNickname}</p>
          <p>Battery: {deviceStatus.battery}%</p>
          <p>Signal Quality: {signalQuality ? `${Math.min(100, (signalQuality * 100).toFixed(0))}%` : 'N/A'}</p>
        </DeviceInfo>
      )}

      <MainContent>
        <Title>Brainwaves</Title>

        <ChartGrid>
          {Object.entries(FrequencyBands).map(([band, info]) => (
            <WaveGraphContainer key={band}>
              <WaveGuide>
                <BandTitle color={info.color}>
                  <ColorDot color={info.color} />
                  {band.charAt(0).toUpperCase() + band.slice(1)}
                  <BandRange>({info.range})</BandRange>
                </BandTitle>
                <BandDescription>
                  {getBandDescription(band.charAt(0).toUpperCase() + band.slice(1))}
                </BandDescription>
              </WaveGuide>
              
              <WaveGraphWrapper>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={rawData}>
                    <XAxis 
                      dataKey="timestamp" 
                      stroke="#666"
                      tick={{ fill: '#666' }}
                      height={20}
                    />
                    <YAxis 
                      domain={autoScale ? ['auto', 'auto'] : [-100, 100]}
                      stroke="#666"
                      tick={{ fill: '#666' }}
                      width={40}
                    />
                    <CartesianGrid strokeDasharray="3 3" stroke="rgba(0,0,0,0.1)" />
                    <Line
                      type="monotone"
                      dataKey={`data[${ChannelNames.indexOf(band.charAt(0).toUpperCase() + band.slice(1))}]`}
                      stroke={info.color}
                      dot={false}
                      isAnimationActive={false}
                      strokeWidth={1.5}
                    />
                    <Line
                      type="monotone"
                      dataKey={`sma[${ChannelNames.indexOf(band.charAt(0).toUpperCase() + band.slice(1))}]`}
                      stroke={info.color}
                      dot={false}
                      isAnimationActive={false}
                      strokeWidth={2}
                      strokeDasharray="5 5"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </WaveGraphWrapper>
            </WaveGraphContainer>
          ))}
          
          <PowerByBandCard>
            <ChartTitle>Absolute Power by Band</ChartTitle>
            <ResponsiveContainer width="100%" height="85%">
              <BarChart data={getPowerByBandChartData()}>
                <XAxis 
                  dataKey="name" 
                  stroke="rgba(255,255,255,0.5)"
                  tick={{ fill: 'rgba(255,255,255,0.5)' }}
                />
                <YAxis 
                  stroke="rgba(255,255,255,0.5)"
                  tick={{ fill: 'rgba(255,255,255,0.5)' }}
                />
                <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
                <Bar dataKey="value">
                  {getPowerByBandChartData().map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Bar>
                <Legend 
                  formatter={(value, entry) => {
                    const item = getPowerByBandChartData().find(d => d.name === value);
                    return `${value} (${item?.range})`;
                  }}
                />
                <Tooltip
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      const data = payload[0].payload;
                      return (
                        <div style={{ 
                          background: 'rgba(255, 255, 255, 0.9)', 
                          padding: '10px',
                          border: '1px solid #ccc',
                          borderRadius: '4px'
                        }}>
                          <p><strong>{data.name} Waves</strong></p>
                          <p>Frequency Range: {data.range}</p>
                          <p>Power: {data.value.toFixed(2)}</p>
                          <p>{getBandDescription(data.name)}</p>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
              </BarChart>
            </ResponsiveContainer>
          </PowerByBandCard>
        </ChartGrid>
      </MainContent>
    </BrainwavesContainer>
  );
}