import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const TrainingContainer = styled.main`
  min-height: 100vh;
  background: linear-gradient(135deg, #2e1a2e 0%, #2e163e 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const TrainingPanel = styled.div`
  margin-top: 4rem;
  background: rgba(0, 0, 0, 0.3);
  padding: 2rem;
  border-radius: 16px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  max-width: 600px;
  width: 90%;
`;

const StageDisplay = styled.div`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  color: ${props => props.active ? '#7579ff' : 'white'};
`;

const Timer = styled.div`
  font-size: 4rem;
  text-align: center;
  margin: 2rem 0;
  font-weight: bold;
  color: ${props => props.active ? '#7579ff' : 'white'};
`;

const Instructions = styled.p`
  font-size: 1.2rem;
  text-align: center;
  margin: 1rem 0;
  line-height: 1.6;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  margin: 2rem 0;
  overflow: hidden;

  div {
    height: 100%;
    background: #7579ff;
    width: ${props => props.progress}%;
    transition: width 0.3s ease;
  }
`;

const Button = styled.button`
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: white;
  padding: 0.8rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  backdrop-filter: blur(10px);
  width: 100%;
  margin-top: 1rem;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-1px);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const NavContainer = styled.nav`
  position: fixed;
  top: 1rem;
  left: 1rem;
  display: flex;
  gap: 1rem;
  z-index: 1000;
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
`;

const DeviceInfo = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: white;
  font-size: 0.9rem;
  background: rgba(0, 0, 0, 0.7);
  padding: 1rem;
  border-radius: 8px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 101;
`;

const StatusIndicator = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.status === 'online' ? '#7579ff' : '#ff4444'};
  margin-right: 8px;
`;

const Input = styled.input`
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: white;
  padding: 0.8rem;
  font-size: 1.1rem;
  width: 100%;
  margin: 1rem 0;
  outline: none;

  &:focus {
    border-color: #7579ff;
  }
`;

const InstructionsCard = styled.div`
  background: rgba(0, 0, 0, 0.4);
  padding: 1.5rem;
  border-radius: 12px;
  margin-bottom: 2rem;
`;

const InstructionStep = styled.div`
  margin: 1rem 0;
  padding-left: 1rem;
  border-left: 2px solid #7579ff;
`;

const Highlight = styled.span`
  color: #7579ff;
  font-weight: 500;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  
  ${Button} {
    flex: 1;
  }
`;

const TrainingInstructions = () => (
  <InstructionsCard>
    <h2>How to Train Your Thought Pattern</h2>
    
    <InstructionStep>
      <h3>1. Choose Your Thought Pattern</h3>
      <p>Pick a specific, repeatable thought pattern such as:</p>
      <ul>
        <li>Imagining moving a specific body part (e.g., lifting your right hand)</li>
        <li>Visualizing a specific color or shape</li>
        <li>Thinking of a specific word or concept</li>
      </ul>
    </InstructionStep>

    <InstructionStep>
      <h3>2. Training Process</h3>
      <p>You'll need to provide <Highlight>20 samples</Highlight>. For each sample:</p>
      <ul>
        <li><Highlight>Baseline (5 seconds)</Highlight>: Clear your mind and relax</li>
        <li><Highlight>Active (5 seconds)</Highlight>: Focus intensely on your chosen thought pattern</li>
        <li>Brief rest between samples</li>
      </ul>
    </InstructionStep>

    <InstructionStep>
      <h3>3. Important Tips</h3>
      <ul>
        <li>Stay as still as possible during recording</li>
        <li>Keep your thought pattern consistent across all samples</li>
        <li>Maintain focus throughout the entire active period</li>
        <li>Take breaks if you feel fatigued</li>
      </ul>
    </InstructionStep>

    <InstructionStep>
      <h3>4. After Training</h3>
      <p>You'll be asked to label your training. Choose a descriptive name like "lift-right-hand" or "visualize-blue" that you can easily remember for future use.</p>
    </InstructionStep>
  </InstructionsCard>
);

export function Training({ neurosity, user }) {
  const [stage, setStage] = useState('idle');
  const [timer, setTimer] = useState(5);
  const [progress, setProgress] = useState(0);
  const [sampleCount, setSampleCount] = useState(0);
  const [totalSamples] = useState(20);
  const [deviceStatus, setDeviceStatus] = useState({ state: 'offline', battery: 0 });
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [trainingLabel, setTrainingLabel] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isTraining, setIsTraining] = useState(false);
  
  const trainingRef = useRef({
    metric: "kinesis",
    label: "",
    experimentId: `exp-${Date.now()}`
  });

  const timersRef = useRef({});

  useEffect(() => {
    if (!neurosity || !user) return;

    const deviceStatusSubscription = neurosity.status().subscribe((status) => {
      setDeviceStatus(status);
    });

    neurosity.getInfo().then((info) => {
      setDeviceInfo(info);
    });

    return () => {
      deviceStatusSubscription.unsubscribe();
    };
  }, [neurosity, user]);

  const stopTraining = () => {
    Object.values(timersRef.current).forEach(timer => {
      clearTimeout(timer);
      clearInterval(timer);
    });
    timersRef.current = {};
    
    setIsTraining(false);
    setStage('idle');
    setTimer(5);
    setProgress(0);
    setSampleCount(0);
  };

  const collectSample = async () => {
    try {
      setIsTraining(true);
      
      // Baseline phase
      setStage('baseline');
      setTimer(5);
      setProgress(0);
      
      await new Promise(resolve => {
        const interval = setInterval(() => {
          setTimer(t => {
            if (t <= 1) {
              clearInterval(interval);
              resolve();
              return 5;
            }
            return t - 1;
          });
          setProgress(p => p + 20);
        }, 1000);
        timersRef.current.baseline = interval;
      });

      // Active phase
      setStage('active');
      setTimer(5);
      setProgress(0);

      // Wait 5 seconds for active thought
      await new Promise(resolve => {
        const interval = setInterval(() => {
          setTimer(t => {
            if (t <= 1) {
              clearInterval(interval);
              resolve();
              return 5;
            }
            return t - 1;
          });
          setProgress(p => p + 20);
        }, 1000);
        timersRef.current.active = interval;
      });

      // Record active thought
      await neurosity.training.record({
        ...trainingRef.current,
        fit: true
      });

      // Update sample count
      setSampleCount(prev => {
        const newCount = prev + 1;
        if (newCount >= totalSamples) {
          setStage('complete');
        }
        return newCount;
      });

      // If not complete, schedule next sample
      if (sampleCount + 1 < totalSamples) {
        const nextTimer = setTimeout(() => {
          collectSample();
        }, 2000);
        timersRef.current.next = nextTimer;
      }
    } catch (error) {
      console.error('Training error:', error);
      setStage('error');
      setIsTraining(false);
    }
  };

  const startTraining = () => {
    setSampleCount(0);
    collectSample();
  };

  const saveTraining = async () => {
    if (!trainingLabel.trim()) {
      alert('Please enter a label for your training');
      return;
    }

    setIsSaving(true);
    try {
      await neurosity.training.save({
        label: trainingLabel,
        experimentId: trainingRef.current.experimentId
      });
      setStage('saved');
    } catch (error) {
      console.error('Error saving training:', error);
      alert('Failed to save training. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const getStageInstructions = () => {
    switch (stage) {
      case 'idle':
        return "When you're ready, press Start to begin training. Remember to stay consistent with your thought pattern.";
      case 'baseline':
        return `Clear your mind and relax. Keep your body still. (Sample ${sampleCount + 1}/${totalSamples})`;
      case 'active':
        return `Now focus intensely on your chosen thought pattern. Keep your body still. (Sample ${sampleCount + 1}/${totalSamples})`;
      case 'processing':
        return "Processing training data...";
      case 'complete':
        return "Training complete! Please enter a descriptive label for your thought pattern.";
      case 'saved':
        return `Training saved as "${trainingLabel}". You can now use this pattern in the Perform section!`;
      case 'error':
        return "An error occurred. Please try again.";
      default:
        return "";
    }
  };

  return (
    <TrainingContainer>
      <NavContainer>
        <NavLink to="/home">Home</NavLink>
        <NavLink to="/calm">Calm</NavLink>
        <NavLink to="/focus">Focus</NavLink>
        <NavLink to="/brainwaves">Brainwaves</NavLink>
        <NavLink to="/predict">Predict</NavLink>
        <NavLink to="/perform">Perform</NavLink>
        <NavLink to="/logout">Logout</NavLink>
      </NavContainer>

      {deviceInfo && (
        <DeviceInfo>
          <p>
            <StatusIndicator status={deviceStatus.state} />
            {deviceStatus.state === 'online' ? 'Connected' : 'Disconnected'}
          </p>
          <p>Device: {deviceInfo.deviceNickname}</p>
          <p>Battery: {deviceStatus.battery}%</p>
        </DeviceInfo>
      )}

      <TrainingPanel>
        <StageDisplay active={stage !== 'idle'}>
          {stage.charAt(0).toUpperCase() + stage.slice(1)}
        </StageDisplay>
        
        {stage === 'idle' && <TrainingInstructions />}

        <Instructions>
          {getStageInstructions()}
        </Instructions>

        {(stage === 'baseline' || stage === 'active') && (
          <>
            <Timer active={true}>{timer}</Timer>
            <ProgressBar progress={progress}>
              <div />
            </ProgressBar>
            <ButtonGroup>
              <Button 
                onClick={stopTraining}
                style={{ backgroundColor: 'rgba(255, 68, 68, 0.2)' }}
              >
                Stop Training
              </Button>
            </ButtonGroup>
          </>
        )}

        {stage === 'complete' && (
          <>
            <Instructions>
              Enter a label for your training (e.g., "lift-right-arm", "think-of-blue"):
            </Instructions>
            <Input
              type="text"
              value={trainingLabel}
              onChange={(e) => setTrainingLabel(e.target.value)}
              placeholder="Enter training label"
            />
            <ButtonGroup>
              <Button 
                onClick={saveTraining}
                disabled={isSaving || !trainingLabel.trim()}
              >
                {isSaving ? 'Saving...' : 'Save Training'}
              </Button>
              <Button 
                onClick={startTraining}
                style={{ backgroundColor: 'rgba(117, 121, 255, 0.2)' }}
              >
                Start New Training
              </Button>
            </ButtonGroup>
          </>
        )}

        {(stage === 'idle' || stage === 'error') && (
          <Button onClick={startTraining}>
            {stage === 'idle' ? 'Start Training' : 'Try Again'}
          </Button>
        )}
      </TrainingPanel>
    </TrainingContainer>
  );
}