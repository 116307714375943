import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import { Nav } from "../components/Nav";
import { take } from "rxjs/operators";

const NavContainer = styled.nav`
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  gap: 1rem;
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
`;

const HomeContainer = styled.div`
  min-height: 100vh;
  background: linear-gradient(135deg, #0A0A0B 0%, #16213e 100%);
  position: relative;
  overflow: hidden;
  padding: 32px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(circle at 20% 30%, rgba(117, 121, 255, 0.1) 0%, transparent 50%),
      radial-gradient(circle at 80% 70%, rgba(178, 36, 239, 0.1) 0%, transparent 50%);
    pointer-events: none;
  }
`;

const Content = styled.div`
  max-width: 1000px;
  margin: 40px auto;
  text-align: center;
  position: relative;
  z-index: 1;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 300;
  letter-spacing: 4px;
  margin-bottom: 40px;
  background: linear-gradient(45deg, #7579ff, #b224ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const IntroCard = styled.div`
  background: rgba(22, 22, 24, 0.8);
  padding: 32px;
  border-radius: 16px;
  margin-bottom: 32px;
  box-shadow: 0 8px 32px rgba(117, 121, 255, 0.1);
  border: 1px solid rgba(117, 121, 255, 0.1);
  backdrop-filter: blur(8px);
  color: rgba(255, 255, 255, 0.9);
  text-align: left;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  margin-top: 32px;
`;

const FeatureCard = styled.div`
  background: rgba(22, 22, 24, 0.6);
  padding: 24px;
  border-radius: 12px;
  border: 1px solid rgba(117, 121, 255, 0.1);
  
  h3 {
    color: #7579ff;
    margin-bottom: 12px;
    font-size: 1.2rem;
  }
  
  p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.9rem;
    line-height: 1.5;
  }
`;

const StatusCard = styled.div`
  background: rgba(22, 22, 24, 0.8);
  padding: 32px;
  border-radius: 16px;
  margin-bottom: 32px;
  box-shadow: 0 8px 32px rgba(117, 121, 255, 0.1);
  border: 1px solid rgba(117, 121, 255, 0.1);
  backdrop-filter: blur(8px);
`;

const RefreshButton = styled.button`
  background: rgba(117, 121, 255, 0.1);
  color: #7579ff;
  border: 1px solid rgba(117, 121, 255, 0.3);
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 16px;

  &:hover {
    background: rgba(117, 121, 255, 0.2);
  }
`;

export function Home({ neurosity, user }) {
  const [status, setStatus] = useState({
    state: "disconnected",
    battery: null,
    charging: null,
    sleepMode: null
  });
  const [deviceInfo, setDeviceInfo] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!neurosity || !user) {
      console.log("Missing required data:", { 
        neurosity: !!neurosity, 
        user: !!user,
        deviceId: neurosity?.deviceId
      });
      return;
    }

    console.log("Setting up device connection with:", {
      deviceId: neurosity.deviceId,
      userId: user.uid
    });
    
    let statusSubscription;
    
    async function setupDevice() {
      try {
        // Get device information
        const info = await neurosity.getInfo();
        console.log("Device info received:", info);
        setDeviceInfo(info);
        
        // Subscribe to status updates
        statusSubscription = neurosity.status().subscribe((statusData) => {
          console.log("Status update received:", statusData);
          setStatus(statusData);
        });
        
      } catch (error) {
        console.error("Error in device setup:", error);
      }
    }

    setupDevice();

    return () => {
      if (statusSubscription) {
        statusSubscription.unsubscribe();
      }
    };
  }, [neurosity, user]);

  const handleRefresh = async () => {
    if (!neurosity || !user) return;
    
    try {
      // Re-fetch device info
      const info = await neurosity.getInfo();
      setDeviceInfo(info);
      
      // Force a new status check
      const statusData = await neurosity.status().pipe(take(1)).toPromise();
      setStatus(statusData);
    } catch (error) {
      console.error("Error refreshing device status:", error);
    }
  };

  return (
    <HomeContainer>
      {user && (
        <NavContainer>
          <NavLink to="/calm">Calm</NavLink>
          <NavLink to="/focus">Focus</NavLink>
          <NavLink to="/brainwaves">Brainwaves</NavLink>
          <NavLink to="/training">Training</NavLink>
          <NavLink to="/predict">Predict</NavLink>
          <NavLink to="/perform">Perform</NavLink>
          <NavLink to="/logout">Logout</NavLink>
        </NavContainer>
      )}
      
      <Content>
        <Title>Crown BCI Dashboard</Title>
        
        <IntroCard>
          <h2 style={styles.introTitle}>Welcome to Your Neural Interface</h2>
          <p style={styles.introText}>
            This dashboard connects with your Neurosity Crown device to provide real-time brain activity monitoring and neural feedback. Use this interface to explore your cognitive states, train mental patterns, and interact with your brain activity in new ways.
          </p>
          
          <FeatureGrid>
            <FeatureCard>
              <h3>Focus & Calm Monitoring</h3>
              <p>Track your concentration and meditation levels in real-time. Perfect for productivity sessions and mindfulness practice.</p>
            </FeatureCard>
            
            <FeatureCard>
              <h3>Brainwave Visualization</h3>
              <p>View your neural activity across different frequency bands (Delta, Theta, Alpha, Beta, and Gamma) with detailed analytics.</p>
            </FeatureCard>
            
            <FeatureCard>
              <h3>Neural Training</h3>
              <p>Train specific mental states and create custom triggers for brain-computer interaction.</p>
            </FeatureCard>
            
            <FeatureCard>
              <h3>Performance Mode</h3>
              <p>Experience your brain activity as dynamic visual art, inspired by neural-reactive performances.</p>
            </FeatureCard>
          </FeatureGrid>
        </IntroCard>
        
        <StatusCard>
          <h2 style={styles.statusTitle}>Device Status</h2>
          <p style={styles.statusText}>
            Connection: <span style={{
              color: status.state === "online" ? styles.statusOnline.color : styles.statusOffline.color
            }}>{status.state}</span>
          </p>
          
          <p style={styles.statusText}>
            Battery: {status.battery !== null ? `${status.battery}%` : 'N/A'}
            {status.charging && ' (Charging)'}
          </p>
          {status.sleepMode && (
            <p style={styles.statusText}>Sleep Mode: Active</p>
          )}
          
          {deviceInfo && (
            <>
              <p style={styles.statusText}>Device Name: {deviceInfo.name}</p>
              <p style={styles.statusText}>Device ID: {deviceInfo.deviceId}</p>
              <p style={styles.statusText}>Channels: {deviceInfo.channels}</p>
              <p style={styles.statusText}>Sampling Rate: {deviceInfo.samplingRate} Hz</p>
              <p style={styles.statusText}>Model: {deviceInfo.model}</p>
              <p style={styles.statusText}>Manufacturer: {deviceInfo.manufacturer}</p>
              <p style={styles.statusText}>OS Version: {deviceInfo.osVersion}</p>
              <p style={styles.statusText}>API Version: {deviceInfo.apiVersion}</p>
            </>
          )}
          
          <RefreshButton onClick={handleRefresh}>
            Refresh Status
          </RefreshButton>
        </StatusCard>
      </Content>
    </HomeContainer>
  );
}

const styles = {
  statusTitle: {
    fontSize: '24px',
    fontWeight: '300',
    marginBottom: '24px',
    color: '#FFFFFF',
  },
  statusText: {
    color: 'rgba(255, 255, 255, 0.7)',
    marginBottom: '12px',
    fontSize: '16px',
  },
  statusOnline: {
    color: '#7579ff',
  },
  statusOffline: {
    color: '#FF3D71',
  },
  introTitle: {
    fontSize: '28px',
    fontWeight: '300',
    marginBottom: '16px',
    color: '#FFFFFF',
    letterSpacing: '1px',
  },
  introText: {
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '16px',
    lineHeight: '1.6',
    marginBottom: '24px',
  }
};
