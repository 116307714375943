import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function Logout({ neurosity, resetState }) {
  const navigate = useNavigate();

  useEffect(() => {
    async function logout() {
      if (neurosity) {
        await neurosity.logout();
      }
      resetState();
      navigate("/");
    }
    logout();
  }, [neurosity, resetState, navigate]);

  return <div>Logging out...</div>;
}