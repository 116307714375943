import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NavContainer = styled.nav`
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  gap: 1rem;
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
`;

export function Nav() {
  return (
    <NavContainer>
      <ul>
        <li>
          <NavLink to="/home">Home</NavLink>
        </li>
        <li>
          <NavLink to="/calm">Calm</NavLink>
        </li>
        <li>
          <NavLink to="/focus">Focus</NavLink>
        </li>
        <li>
          <NavLink to="/brainwaves">Brainwaves</NavLink>
        </li>
        <li>
          <NavLink to="/training">Training</NavLink>
        </li>
        <li>
          <NavLink to="/perform">Perform</NavLink>
        </li>
        <li>
          <NavLink to="/logout">Logout</NavLink>
        </li>
      </ul>
    </NavContainer>
  );
}