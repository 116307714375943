import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const FocusContainer = styled.main`
  min-height: 100vh;
  background: linear-gradient(135deg, #1a2f1a 0%, #1b381b 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const DeviceInfo = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: white;
  font-size: 0.9rem;
  text-align: right;
  background: rgba(255, 255, 255, 0.05);
  padding: 1rem;
  border-radius: 8px;
  backdrop-filter: blur(5px);
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FocusOrb = styled.div`
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background: radial-gradient(circle at 30% 30%, #4CAF50, #2E7D32);
  box-shadow: 0 0 60px rgba(76, 175, 80, ${props => props.focus});
  transition: all 0.8s ease;
  transform: scale(${props => 0.8 + props.focus * 0.4});
  opacity: ${props => 0.6 + props.focus * 0.4};
  margin: 2rem 0;
`;

const FocusText = styled.h2`
  color: white;
  font-size: 2rem;
  margin-top: 2rem;
  font-weight: 300;
  text-align: center;
`;

const FocusMessage = styled.p`
  color: #a0a0a0;
  margin-top: 1rem;
  font-size: 1.2rem;
  text-align: center;
`;

const StatusIndicator = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.status === 'online' ? '#4CAF50' : '#ff4444'};
  margin-right: 8px;
`;

const StatusText = styled.span`
  color: white;
`;

const NavContainer = styled.nav`
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  gap: 1rem;
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
`;

export function Focus({ user, neurosity }) {
  const [focus, setFocus] = useState(0);
  const [deviceStatus, setDeviceStatus] = useState({});
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [signalQuality, setSignalQuality] = useState(null);

  useEffect(() => {
    if (!user || !neurosity) return;

    // Get device info
    neurosity.getInfo().then(setDeviceInfo);

    // Subscribe to status
    const statusSubscription = neurosity.status().subscribe(setDeviceStatus);

    // Subscribe to focus data
    const focusSubscription = neurosity.focus().subscribe((focus) => {
      setFocus(Number(focus.probability.toFixed(2)));
    });

    // Updated signal quality subscription
    const signalQualitySubscription = neurosity.signalQuality().subscribe((quality) => {
      // quality is an array of channels, we'll take the average
      if (quality && quality.length > 0) {
        const average = quality.reduce((sum, channel) => sum + channel.standardDeviation, 0) / quality.length;
        setSignalQuality(average);
      }
    });

    return () => {
      statusSubscription.unsubscribe();
      focusSubscription.unsubscribe();
      signalQualitySubscription.unsubscribe();
    };
  }, [user, neurosity]);

  const getFocusMessage = (focusValue) => {
    if (focusValue < 0.3) return "Building concentration...";
    if (focusValue < 0.6) return "Getting focused...";
    return "You are highly focused!";
  };

  return (
    <FocusContainer>
      {user && (
        <NavContainer>
          <NavLink to="/home">Home</NavLink>
          <NavLink to="/calm">Calm</NavLink>
          <NavLink to="/brainwaves">Brainwaves</NavLink>
          <NavLink to="/training">Training</NavLink>
          <NavLink to="/predict">Predict</NavLink>
          <NavLink to="/perform">Perform</NavLink>
          <NavLink to="/logout">Logout</NavLink>
        </NavContainer>
      )}
      
      {deviceInfo && (
        <DeviceInfo>
          <p>
            <StatusIndicator status={deviceStatus.state} />
            <StatusText>
              {deviceStatus.state === 'online' ? 'Connected' : 'Disconnected'}
            </StatusText>
          </p>
          <p>Device: {deviceInfo.deviceNickname}</p>
          <p>Battery: {deviceStatus.battery}%</p>
          <p>Signal Quality: {signalQuality ? `${Math.min(100, (signalQuality * 100).toFixed(0))}%` : 'N/A'}</p>
        </DeviceInfo>
      )}

      <MainContent>
        <FocusOrb focus={focus} />
        <FocusText>Focus Level: {(focus * 100).toFixed(0)}%</FocusText>
        <FocusMessage>{getFocusMessage(focus)}</FocusMessage>
      </MainContent>
    </FocusContainer>
  );
}