import React, { useState } from "react";
import { LoginForm } from "../components/LoginForm";
import { Neurosity } from "@neurosity/sdk";
import { useNavigate } from 'react-router-dom';

export function Login({ setNeurosity, setDeviceId, setUser }) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleLogin({ email, password, deviceId }) {
    try {
      setLoading(true);
      setError("");
      
      // Create a new instance with the deviceId
      const client = new Neurosity({
        deviceId: deviceId
      });
      
      // Login first
      const user = await client.login({ email, password });
      console.log("Login successful", user);
      
      // Verify the device exists
      const devices = await client.getDevices();
      console.log("Available devices:", devices);
      
      // Find the device with matching deviceId
      const selectedDevice = devices.find(device => device.deviceId === deviceId);
      
      if (!selectedDevice) {
        throw new Error(`Device ${deviceId} not found. Available devices: ${devices.map(d => d.deviceId).join(', ')}`);
      }

      console.log("Found device:", selectedDevice);
      
      // Select the device using the callback approach
      await client.selectDevice((deviceList) => {
        const device = deviceList.find(d => d.deviceId === deviceId);
        console.log("Selecting device from list:", device);
        return device;
      });
      
      console.log("Device selected successfully");
      
      // Update all the necessary state
      setDeviceId(deviceId);
      setUser(user);
      setNeurosity(client);
      
      // Navigate to home
      navigate('/home', { replace: true });
      
    } catch (error) {
      console.error("Login error details:", error);
      setError(error.message || "Failed to login. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  return <LoginForm onLogin={handleLogin} loading={loading} error={error} />;
}